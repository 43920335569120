<template>
<span>
    <v-layout mb-4>
        <v-flex xs10 ml-3>
            <h2 class="lime--text lighten-1 pt-3">Gerenciar Cor Produto - Edição</h2>
        </v-flex>
        <v-flex xs2 mr-3 text-right>
            <v-btn v-if="productcolor" color="blue-grey" class="black--text" :to="{name: 'product.colors'}">
                <v-icon dark>keyboard_arrow_left</v-icon>
                Voltar
            </v-btn>
        </v-flex>
    </v-layout>

    <form-product-color :productcolor="productcolor" :update="true"></form-product-color>
</span>
</template>

<script>
import FormProductColorComponent from "./partial/FormProductColorComponent";

export default {
    name: "EditProductColorComponent",
    created() {
        this.loadProductColor()
    },
    props: {
        prod_co_id: {
            require: true
        }
    },
    data() {
        return {
            productcolor: {
                prod_co_id: '',
                prod_co_nome: '',
                prod_co_hexa: '',
                prod_co_uint: '',
                prod_co_cod: '',
                prod_co_img: '',
                prod_co_tipo: '',
                prod_co_status: false,
            },
        }
    },
    methods: {
        loadProductColor() {
            this.$store.dispatch('loadProductColor', this.prod_co_id)
                .then(response => {
                    this.productcolor = response
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: 'Erro',
                        text: 'Dados não localizado',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },

    },
    components: {
        formProductColor: FormProductColorComponent
    }
};
</script>

<style scoped>

</style>
